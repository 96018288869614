/*****************弹窗模块*************/
.cncw_dialog {
    width: 518px;
    background: #fff;
    border-radius: 3px;
}

.cncw_dialog .hd {
	padding: 0 10px;
    color: #666;
    height: 36px;
    background: #f6f6f6;
    line-height: 36px;
    position: relative;
    font-weight: bold;
    font-size: 14px;
    border-radius: 3px 3px 0 0;
}

.cncw_dialog .hd .close {
    cursor: pointer;
    background: #f6f6f6 url(../img/icon-dialogClose.png) no-repeat center center;
    height: 24px;
    width: 24px;
    border: 1px solid #f6f6f6;
    border-radius: 2px;
    position: absolute;
    top: 5px;
    right: 13px;
}

.cncw_dialog .hd .close:hover {
    background-color: #fff;
    border: 1px solid #d1d1d1;
}

.cncw_dialog .btns {
    height: 30px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
    background: #fff;
}

.cncw_dialog .btn-dialog-confirm{
	height:28px;
    line-height:28px;
	display:inline-block;
    padding:0 30px;
	margin-right:20px;
    color:#fff;
    background: #ff8019;
    border: 1px solid #ff8019;
    border-radius: 3px;
}

.cncw_dialog .btn-dialog-cancel {
	height:28px;
    line-height:28px;
	display:inline-block;
    padding:0 30px;
	margin-right:20px;
    color:#fff;
    background: #b5b5b5;
    border: 1px solid #b5b5b5;
    border-radius: 3px;
}

/*=============== 警告样式 ================*/
#success_alert{
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border:1px solid #ccc;
    width:auto;
    height:60px;
    z-index:10000;
    line-height:60px;
    color:#333;/*5fb800*/
    font-size:14px;
    background:url(../img/icon-dialogTip.png) no-repeat 10px 10px #fff;
    border-radius:5px;
    font-weight:bold
}
#success_alert p{
    line-height:68px;
    padding-left:60px;
    text-align:left;
    padding-right:20px
}
#fail_alert{
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border:1px solid #ccc;
    width:auto;
    height:60px;
    z-index:10000;
    line-height:60px;
    color:#333;/*ff3d23*/
    font-size:14px;
    background:url(../img/icon-dialogTip.png) no-repeat 10px -40px #fff;
    border-radius:5px;
    font-weight:bold
}
#fail_alert p{
    line-height:68px;
    padding-left:60px;
    text-align:left;
    padding-right:20px
}
#confirm_alert{
    background:#fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border:1px solid #ccc;
    width:300px;
    z-index:10000;
    color:#333;/*ff3d23*/
    border-radius:5px;
    padding:20px;
    text-align:right;
}
#confirm_alert p{
    text-align:left;
    font-size:14px;
    font-weight:bold;
    line-height:25px;
    padding-bottom:10px;
}
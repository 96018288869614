.page_global{
    float:right;
}
.page_global a{
    vertical-align: top;
    padding:0 10px;
    height:28px;
    line-height:28px;
    display:inline-block;
    font-size:14px;
    border:1px #d1d1d1 solid;
    background:#fff;
    border-radius: 2px;
}
.page_global a:link,.page_global a:visited{color:#666666;}
.page_global a.on:link,.page_global a.on:visited{
    background:#f2f2f2;
    border:1px #d1d1d1 solid;
    color:#333;
}
.page_global a:hover{
    background:#69b2ef;
    border:1px #69b2ef solid;
    color:#fff;
}
.page_global a.on{
    background:#f2f2f2;
}
.page_global span{
    padding: 0 10px 0 6px;
}
.page_global input{
	width:40px;
	height:26px;
	padding:0 5px;
	margin:0 5px;
	border:1px solid #d1d1d1;
}
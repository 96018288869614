.page-order-signUp3{
	
	.mod-process{
		width:1024px;
		height:34px;
		margin:40px auto;
		background:url(../img/order-process-bar01.png)
	}
	
	
	.order-infoCase-box{
		.hd{
			height:40px;
			line-height:40px;
			font-size:16px;
			color:#333;
			border-bottom:1px solid #ddd;
			
			.icon{margin:0px 3px -3px 0;}
			.txt{
				font-size:12px;
				color:#666;
			}
		}
		
		
		.coverBox{
			margin:15px 0;
			padding:15px 10px 20px;
			background:#f9f9f9;
			
			/*表单类文字左右结构*/
			.mod-cover{
				min-height:20px;
				padding:0 0 10px 85px;
				
				.cover-title{
					width:85px;
					float:left;
					margin-left:-85px;
					text-align:right;
					color:#666;
					
					em{
						color:#f00;
						margin-right:5px;
					}
				}
				.cover-detail{
					color:#333;
					word-wrap:break-word;
					
					p{padding-bottom:5px;}
					.total{
						border-top:1px dashed #cbcaca;
						margin-top:10px;
						padding-top:10px;
						text-align:right;
						color:#999;
						font-size:15px;
						font-weight:700;
						
						em{
							font-size:22px;
							color:#f86900;
						}
						.yen{font-size:14px;}
					}
				}
				
				
			}
			.info-title{
				display:block;
				padding:0 0 13px 35px;
				color:#333;
				
				em{color:#ec0303;}
			}
			.info-more{
				padding-top:15px;
				color:#e0e0e0;
				border-top:1px solid #e0e0e0;
			}
			.mod-detail-tb{
				width:100%;
				
				td{
					vertical-align:top;
				}
				.t2{
					text-align:right;
					color:#999;
				}
				.t3{
					font-size:16px;
					font-weight:700;
					text-align:right;
					color:#f86900;
					
					.yen{font-size:12px;}
				}
			}
			
			
		}
		
		.agreeement-box{
			height:320px;
			margin-top:15px;
			overflow-y:auto;
			padding:10px 14px 20px;
			border:1px solid #ccc;
			color:#666;
			
			h5{
				font-size:14px;
				color:#333;
				padding-top:8px;
			}
			p{
				margin-bottom:6px;
			}
			
		}
		.agreeement-bar{
			margin-top:15px;
			background-color:#f4f4f4;
			
			.tool{
				height:28px;
				line-height:28px;
				padding:0 8px;
				color:#0e98df;
				
				.btn-show{
					float:right;
					padding-right:15px;
					cursor:pointer;
					position:relative;
					
					i.down{
						position:absolute;
						top:12px;
						right:0;
						.arrow(#3498db, down, 5px);
					}
				}
				.icon-order-terms{
					margin:0 4px -1px 0;
				}
			}
			.agreeement-downMore{
				display:none;
				padding:5px 8px 15px;
				line-height:20px;
				color:#333;
			}
		}
		
	}
}

.ui-btn-next{
	height:36px;
	line-height:36px;
	display:inline-block;
	padding:0 30px;
	font-size:16px;
	color:#fff;
	background:#0e98df;
	border:1px solid #0e98df;
	border-radius:5px;
}

.ui-preBtn{
	font-size:16px;
	color:#999;
	text-decoration:underline;
	margin-right:15px;
}

.w1000{
	width:1000px;
	margin:0 auto;
}




.page-order-signUp2{
			
	.mod-process{
		width:1024px;
		height:34px;
		margin:40px auto;
		background:url(../img/order-process-bar01.png)
	}
	
	
	.order-infoCase-box{
		.hd{
			height:40px;
			line-height:40px;
			font-size:16px;
			color:#333;
			border-bottom:1px solid #ddd;
			
			.icon{margin:0px 3px -3px 0;}
			.txt{
				font-size:12px;
				color:#666;
			}
		}
		
		.tip{
			padding:15px 0 10px;
			color:#666;
			line-height:25px;
		}
		.coverBox{
			margin:15px 0;
			padding:15px 10px 20px;
			background:#f9f9f9;
			
			/*表单类文字左右结构*/
			.mod-cover{
				min-height:20px;
				padding:0 0 10px 85px;
				
				.cover-title{
					width:85px;
					float:left;
					margin-left:-85px;
					text-align:right;
					color:#666;
					
					em{
						color:#f00;
						margin-right:5px;
					}
				}
				.cover-detail{
					color:#333;
					word-wrap:break-word;
				
				}
			}
			
			
		}
		
	}
}

.ui-btn-next{
	height:36px;
	line-height:36px;
	display:inline-block;
	padding:0 30px;
	font-size:16px;
	color:#fff;
	background:#0e98df;
	border:1px solid #0e98df;
	border-radius:5px;
}
.ui-preBtn{
	font-size:16px;
	color:#999;
	text-decoration:underline;
	margin-right:15px;
}


.w1000{
	width:1000px;
	margin:0 auto;
}


.mod-forms{
	.ui-form{margin-bottom:10px;}
}



.mod-border-table{
	width:100%;
	border:1px solid #ccc;
	color:#999;
	
	th{
		height:34px;
		line-height:34px;
		text-align:center;
		background:#f6f6f6;
		border-bottom:1px solid #ccc;
		
		em{color:#f00;}
	}
	td{
		padding:6px;
		border-bottom:1px solid #ccc;
		
	}
	.first{border-right:1px solid #ccc;}
}
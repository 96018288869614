/**************** 宽度 **************/
.ui-wAuto{width:auto !important;}
.ui-w20{width:20px !important;}
.ui-w30{width:30px !important;}
.ui-w40{width:40px !important;}
.ui-w50{width:50px !important;}
.ui-w60{width:60px !important;}
.ui-w65{width:65px !important;}
.ui-w70{width:70px !important;}
.ui-w75{width:75px !important;}
.ui-w80{width:80px !important;}
.ui-w85{width:85px !important;}
.ui-w90{width:90px !important;}
.ui-w95{width:95px !important;}
.ui-w100{width:100px !important;}
.ui-w105{width:105px !important;}
.ui-w110{width:110px !important;}
.ui-w115{width:115px !important;}
.ui-w120{width:120px !important;}
.ui-w125{width:125px !important;}
.ui-w130{width:130px !important;}
.ui-w135{width:135px !important;}
.ui-w140{width:140px !important;}
.ui-w145{width:145px !important;}
.ui-w150{width:150px !important;}
.ui-w155{width:155px !important;}
.ui-w160{width:160px !important;}
.ui-w200{width:200px !important;}
.ui-w220{width:220px !important;}
.ui-w250{width:250px !important;}
.ui-w300{width:300px !important;}
.ui-w400{width:400px !important;}
.ui-w500{width:500px !important;}
.ui-w600{width:600px !important;}
.ui-w1050{width:1050px !important;}
/*百分比宽度*/
.ui-w30per{width:30% !important;}
.ui-w33per{width:33% !important;}
.ui-w40per{width:40% !important;}
.ui-w45per{width:45% !important;}
.ui-w50per{width:50% !important;}
.ui-w60per{width:60% !important;}
.ui-w70per{width:70% !important;}
.ui-w80per{width:80% !important;}
.ui-w100per{width:100% !important;}

/**************** 高度 **************/
.ui-hAuto{height: auto !important;}
.ui-h140{height: 140px !important;}

/*************** 间隙 *************/
/* margin */
.ui-m0{margin:0 !important;}
.ui-mt0{margin-top:0px !important;}
.ui-mb0{margin-bottom:0px !important;}
.ui-ml0{margin-left:0px !important;}
.ui-mr0{margin-right:0px !important;}
.ui-m5{margin:5px}
.ui-mt5{margin-top:5px}
.ui-mb5{margin-bottom:5px}
.ui-ml5{margin-left:5px}
.ui-mr5{margin-right:5px}

.ui-m10{margin:10px}
.ui-mt10{margin-top:10px}
.ui-mb10{margin-bottom:10px}
.ui-ml10{margin-left:10px}
.ui-mr10{margin-right:10px}

.ui-m20{margin:20px}
.ui-mt20{margin-top:20px}
.ui-mb20{margin-bottom:20px}
.ui-ml20{margin-left:20px}
.ui-mr20{margin-right:20px}

.ui-ml130{margin-left:130px}

/* padding */
.ui-p0{padding:0 !important;}
.ui-pt0{padding-top:0px !important;}
.ui-pb0{padding-bottom:0px !important;}
.ui-pl0{padding-left:0px !important;}
.ui-pr0{padding-right:0px !important;}
.ui-p5{padding:5px}
.ui-pt5{padding-top:5px}
.ui-pb5{padding-bottom:5px}
.ui-pl5{padding-left:5px}
.ui-pr5{padding-right:5px}

.ui-p10{padding:10px}
.ui-pt10{padding-top:10px}
.ui-pb10{padding-bottom:10px}
.ui-pl10{padding-left:10px !important;}
.ui-pr10{padding-right:10px}

/***************** 字体 ****************/
.ui-fontBold{font-weight:bold}
.ui-font12{font-size:12px;}
.ui-font14{font-size:14px;}
.ui-font16{font-size:16px;}
.ui-font18{font-size:18px;}

/***************** 对齐 ****************/
.ui-textCenter{text-align: center !important;}
.ui-textLeft{text-align: left !important;}
.ui-textRight{text-align: right !important;}
.ui-textJustify{text-align: justify !important;}

/***************** 边框 **************/
.ui-border{border:1px solid @col-border;}
.ui-borderTop{border-top:1px solid @col-border;}
.ui-borderBottom{border-bottom:1px solid @col-border;}
.ui-borderLeft{border-left:1px solid @col-border;}
.ui-borderRight{border-right:1px solid @col-border;}

/**************** 颜色 **************/
/* 黑色系 */
.col-black{color:@col-black !important;}
.col-black2{color:@col-block2 !important;}
.col-black3{color:@col-block3 !important;}
.col-black4{color:@col-block4 !important;}

/* 灰色系 */
.col-gray{color:@col-gray}
.col-gray2{color:@col-gray2}
.col-gray3{color:@col-gray3}
.col-gray4{color:@col-gray4}

/* 黄色系 */
.col-yellow{color:@col-yellow}
.col-yellow2{color:@col-yellow2}
.col-yellow3{color:@col-yellow3}

/* 蓝色系 */
.col-blue{color:@col-blue}
.col-blue2{color:@col-blue2}
.col-blue3{color:@col-blue3}

/* 红色系 */
.col-red{color:@col-red !important;}

/***************** floatCenter ***********/
/*调用需要给ui-floatCenter重置高度、ui-floatCenter-item重置间距，否则就默认*/
.ui-floatCenter{position:relative;height:30px;overflow:hidden;}
	.ui-sl-floatCenter{float:left;position:relative;left:50%}
		.ui-floatCenter-item{position:relative;right:50%;float:left;margin:0 5px}

/***************** 折行 ***********/
.ui-wspace{
    white-space: inherit !important;
}

/***************** 弹窗最大高度限定 ***********/
.ui-dialog-MaxSpace{
    max-height:300px;
	overflow-y:auto;
}
a{color:#008ee8;}
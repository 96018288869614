.page-order-signUp1{
			
	.mod-process{
		width:1024px;
		height:34px;
		margin:40px auto;
		background:url(../img/order-process-bar01.png)
	}
	.order-infoClear-box{
		.hd{
			height:40px;
			line-height:40px;
			padding:0 13px;
			background:#ff9600;
			font-size:16px;
			color:#fff;
			
			.icon{margin:0px 7px -2px 0;}
		}
		
		.bd{
			padding:0 12px;
			border:1px solid #ffc674;
			border-top:0;
		}
		.order-info-list{
			padding:20px 0 15px;
			border-bottom:1px dashed #cbcaca;
			
			.list-hd{
				font-size:14px;
				color:#333;
			}
			table{
				width:100%; 
				
				td{
					padding:5px 0;
					color:#999;
				}
				b{
					display:block;
					text-align:right;
					color:#f86900;
				}
			}
		}
		.mod-btnArea{
			text-align:center;
			padding-bottom:25px;
			
			.total{
				padding:10px 0 20px;
				text-align:left;
				color:#333;
				font-weight:700;
				
				em{color:#f86900; font-weight:700;}
			}
			
		}
	}
	
	.order-infoCase-box{
		.hd{
			height:40px;
			line-height:40px;
			font-size:16px;
			color:#333;
			border-bottom:1px solid #ddd;
			
			.icon{margin:0px 3px -3px 0;}
			
		}
		
		.coverBox{
			margin:15px 0;
			padding:15px 10px 20px;
			background:#f9f9f9;
			
			/*表单类文字左右结构*/
			.mod-cover{
				min-height:20px;
				padding:0 0 10px 85px;
				
				.cover-title{
					width:85px;
					float:left;
					margin-left:-85px;
					text-align:right;
					color:#666;
					
					em{
						color:#f00;
						margin-right:5px;
					}
				}
				.cover-detail{
					//float:left;
					color:#333;
					word-wrap:break-word;
					
					p{padding-bottom:5px;}
					.total{
						border-top:1px dashed #cbcaca;
						margin-top:10px;
						padding-top:10px;
						text-align:right;
						color:#999;
						font-size:15px;
						font-weight:700;
						
						em{
							font-size:22px;
							color:#f86900;
						}
						.yen{font-size:14px;}
					}
				}
			}
			em.num{
				color:#f86900;
				font-weight:700;
			}
			
			
		}
		.mod-totalArea{
			height:60px;
			margin:15px 0;
			padding:0 50px 0 25px;
			background-color:#fff3ec;
			
			.totalNum{
				float:right;
				margin-top:20px;
				color:#666;
				
				em{
					color:#f86900;
					font-size:14px;
					font-weight:700;
				}
			}
			
			.ui-form{
				float:left;
				margin-top:15px;
				
				.ui-label{color:#666;}
			}
		}
		
		
		.mod-introTxt{
			margin:15px 0;
			
			.it-hd{
				height:30px;
				background-color:#f9f9f9;
				
				h5{
					float:left;
					height:15px;
					line-height:15px;
					margin:8px 0 0 10px;
					padding-left:8px;
					font-size:14px;
					color:#333;
					border-left:4px solid #0e98df;
				}
			}
			.it-bd{
				padding:10px;
				line-height:22px;
				font-size:14px;
				color:#555;
			}
		}
	}
}

.ui-btn-next{
	height:36px;
	line-height:36px;
	display:inline-block;
	padding:0 30px;
	font-size:16px;
	color:#fff;
	background:#0e98df;
	border:1px solid #0e98df;
	border-radius:5px;
}
.ui-preBtn{
	font-size:16px;
	color:#999;
	text-decoration:underline;
	margin-right:15px;
}
.lyt-wrapper{
	width:780px;
	margin:0 auto;
	padding-right:220px;
	
}
.lyt-side{
	width:200px;
	float:right;
	margin-right:-220px;
}
.lyt-main{
	padding:10px 20px;
	margin-bottom:20px;
	border:1px solid #e0e0e0;
}



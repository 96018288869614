.l-tree{	span{		display:inline-block;		cursor:pointer;		outline:none;		background:url(../img/l-tree.png) no-repeat;	}		li{		//white-space: nowrap;	}		.l-tree-selectedNode{		padding-top: 0px;		background-color: #FFE6B0;		color: black;		height: 16px;		border: 1px #FFB951 solid;		opacity: 0.8;	}
	.l-tree-ul{
		padding:0 0 0 18px;
	}	
	.l-tree-line{
		background:url(../img/l-tree-line.gif) 0 0 repeat-y;
	}	
	.l-tree-open{
		background-position: -92px 0;
	}
	.l-tree-close{
		background-position: -74px 0;
	}	.l-tree-switch{		width:18px;		height:18px;		background-position: -56px -18px;	}	.l-tree-lastSwitch{		background-position: -56px -36px;	}		.l-tree-node{vertical-align:top;}		.l-tree-check{		height:13px;		width:13px;		margin:2px;	}	.l-tree-checkbox{		&:hover{			background-position: 0 -14px;		}	}	.l-tree-checkbox-checked{		background-position: -14px 0;		&:hover{			background-position: -14px -14px;		}	}	.l-tree-checkbox-checked-part{		background-position: -14px -28px;		&:hover{			background-position: -14px -42px;		}	}	.l-tree-radio{		background-position: -28px 0;		&:hover{			background-position: -28px -14px;		}	}	.l-tree-radio-checked{		background-position: -42px 0;		&:hover{			background-position: -42px -14px;		}	}			.l-tree-ico{		width:16px;		height:16px;		background-position: -110px -32px;		margin:1px 3px 0 0;	}		.l-tree-text{		cursor:pointer;		vertical-align:top;		margin:1px 0 0;		color:#333;	}
	
	.l-tree-parentNode{
		.l-tree-lastSwitch{
			background-position: -56px -36px;
		}
		.l-tree-open{
			background-position: -92px -18px;
		}
		.l-tree-close{
			background-position: -74px -18px;
		}		.l-tree-ico{			background-position: -110px -16px;		}
	}	.l-tree-parentFirstNode{		.l-tree-open{			background-position: -92px 0;		}		.l-tree-close{			background-position: -74px 0;		}	}		.l-tree-item{		//position:relative;		white-space:nowrap;		height:18px;		line-height:18px;	}
	
	.l-tree-lastItem{
		.l-tree-lastItem .l-tree-parentNode{
			.l-tree-lastSwitch{
				background-position: -56px -36px;
			}
			.l-tree-open{
				background-position: -92px -36px;
			}
			.l-tree-close{
				background-position: -74px -36px;
			}
		}
	}}
/*详细页表单模块*/
.mod-forms{

	padding:20px 20px 0;
	
	label{
		padding:0 0 15px;
	}
	textarea,.guang-editor-wrap{
		margin:0 10px 0 0;
	}
	.ui-label{
		width:120px;
		margin:0 10px 0 0;
	}
	.ui-input{
		width:200px;
		margin:0 10px 0 0;
	}
	.ui-input-date{
		width:183px;
	}
	.ui-select{
		margin:0 10px 0 0;
	}
	.ui-labelBox{
		label{padding:0}
		padding:0 0 15px;
		.ui-labelNoWidth{width:auto;padding:0 10px 0 0}
	}
	.ui-checkbox-label,.ui-radio-label{
		width:auto;
	}
}

/*表单左右结构*/
.ui-cover{
	min-height:20px;
	padding:0 0 10px 100px;
	
	.ui-cover-title{
		width:100px;
		float:left;
		margin-left:-100px;
		text-align:right;
		color:#888;
		
		em{
			color:#f00;
			margin-right:5px;
		}
	}
	.ui-cover-detail{
		padding-left:10px;
		color:#555;
		word-wrap:break-word;
	}
}
/*
Icon classes can be used entirely standalone. They are named after their original file names.

```html
<!-- `display: block` sprite -->
<div class="icon-home"></div>

<!-- `display: inline-block` sprite -->
<img class="icon-home" />
```
*/
.icon-address {
  background-image: url(../img/sprite.png);
  background-position: -41px 0px;
  width: 16px;
  height: 20px;
}
.icon-calc {
  background-image: url(../img/sprite.png);
  background-position: 0px -44px;
  width: 13px;
  height: 16px;
}
.icon-case {
  background-image: url(../img/sprite.png);
  background-position: -57px 0px;
  width: 18px;
  height: 17px;
}
.icon-direction {
  background-image: url(../img/sprite.png);
  background-position: -20px 0px;
  width: 21px;
  height: 19px;
}
.icon-order-agreeement {
  background-image: url(../img/sprite.png);
  background-position: 0px -21px;
  width: 17px;
  height: 23px;
}
.icon-order-client {
  background-image: url(../img/sprite.png);
  background-position: 0px 0px;
  width: 20px;
  height: 21px;
}
.icon-order-contact {
  background-image: url(../img/sprite.png);
  background-position: -17px -21px;
  width: 21px;
  height: 16px;
}
.icon-order-info {
  background-image: url(../img/sprite.png);
  background-position: -41px -20px;
  width: 14px;
  height: 17px;
}
.icon-order-terms {
  background-image: url(../img/sprite.png);
  background-position: -13px -44px;
  width: 11px;
  height: 11px;
}
.icon-wallet {
  background-image: url(../img/sprite.png);
  background-position: -57px -17px;
  width: 15px;
  height: 20px;
}
